<template>
  <div>
    <Popup class="popupOuter" :delivery="lowestDelivery" :total-price="totalPrice" v-if="isDisplayed" />
  </div>
</template>

<script>
import Popup from '@/components/Popup.vue'
import axios from 'axios'

const apiBasePath = process.env.VUE_APP_API_BASE_URI

export default {
  name: 'shop',
  data: function(){
    return {
      deliveries: [],
      colormeObj: null,
      isDisplayed: false,
      lowestDelivery: {},
    }
  },
  components: {
    Popup
  },
  computed: {
    totalPrice: function() {
      return window.Colorme.basket.total_price;
    }
  },
  created: async function(){
    const shopId = window.Colorme.shop.account_id;
    const response = await axios.get(`${apiBasePath}/deliveries/${shopId}`);
    const hasFreeShippingDeliveries = response.data.deliveries.filter(delivery => delivery.charge_free_limit !== null && delivery.charge_free_type === 'free_to_limit');
    const ngDeliveryIds = this.createNgList();
    const availableDeliveries = hasFreeShippingDeliveries.filter(delivery => ngDeliveryIds.indexOf(delivery.id) < 0);
    this.lowestDelivery = availableDeliveries.sort((a, b) => a.charge_free_limit - b.charge_free_limit)[0];
    this.isDisplayed = availableDeliveries.length > 0 && window.Colorme.basket.items.length > 0;
  },
  methods: {
    createNgList: function(){
      const basketItems = window.Colorme.basket.items;
      return basketItems.reduce((accumulator, val) => accumulator.concat(val.ng_delivery_ids), []).filter((id,index,array) => array.indexOf(id) === index);
    }
  }
}
</script>

<style scoped>
.popupOuter {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10000;
}
</style>
