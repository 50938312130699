import Vue from 'vue'
import Shop from './Shop.vue'

(function (document) {
  Vue.config.productionTip = false

  const component = new Vue({
    render: h => h(Shop),
  }).$mount()

  Vue.filter('convertToPrice', value => String(value).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'));

  document.body.appendChild(component.$el);
})(document)
