<template>
  <div class="freeShippingPopupOuter" :class='{popupMinimized:isMinimized}' :style="outerWidthStyle">
    <div class="iconArea" :class='{popupMinimized: isMinimized}'><v-btn icon x-large class="iconAreaButton" @click="popupOpen"><v-icon large class="truckIcon">fas fa-truck</v-icon></v-btn></div>
    <div class="freeShippingDetails" :class='{popupMinimized:isMinimized}'>
      <div class="remainingPriceArea" :class='{displayNone: isMinimized}'>{{ this.delivery.name }} あと<span class="remainingPrice">{{ remainingPrice | convertToPrice }}</span>円で<span class="freeShippingText">配送料無料</span></div>
      <div class="hideButtonArea"  :class='{displayNone: isMinimized}'><v-btn icon @click="popupMinimize"><v-icon class="closeIcon">fas fa-times</v-icon></v-btn></div>
    </div>
  </div>
</template>

<script>
import '@fortawesome/fontawesome-free/css/all.css'

export default {
  name: 'Popup',
  props: {
    totalPrice: {
      type: Number,
      required: true
    },
    delivery: {
      type: Object,
      required: true
    }
  },
  data: function(){
    return {
      isMinimized: true,
      outerWidth: 0
    }
  },
  computed: {
    remainingPrice: function() {
      return Math.max(this.delivery.charge_free_limit - this.totalPrice, 0);
    },
    outerWidthStyle: function() {
      return {
        width: `${Math.min(this.outerWidth - 20, 480)}px`
      };
    }
  },
  created: function() {
    const isClosed = this.getIsClosed();
    this.isMinimized = this.getIsMinimizedState(isClosed, this.totalPrice);
    window.addEventListener('resize', () => this.outerWidth = window.innerWidth);
  },
  methods: {
    popupOpen: function(){
      this.isMinimized = false;
      sessionStorage.setItem('isClosed', "false");
    },
    popupMinimize: function(){
      this.isMinimized = true;
      sessionStorage.setItem('isClosed', "true");
    },
    getIsMinimizedState: function(isClosed, totalPrice){
      const isMinimized = !(!isClosed && totalPrice > 0);
      return isMinimized;
    },
    getIsClosed: function(){
      const isClosed = sessionStorage.getItem("isClosed");
      return !!isClosed && isClosed === 'true';
    }
  }
}
</script>

<style scoped>
button {
  border-width: 0px;
}

.freeShippingPopupOuter {
  position: relative;
  box-shadow: 0px 1px 3px rgba(0,0,0,0.4);
  border-radius: 10px;
  background-color:#EEE;
  width: 480px;
  height: 80px;
  transition-duration: 0.7s;
  transition-timing-function: ease;
  overflow: hidden;
}
.freeShippingDetails {
  padding-left: 80px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.iconArea{
  height: 100%;
  width: 80px;
  position: absolute;
  left: 0;
  text-align: center;
  border-radius: 10px 0px 0px 10px;
  background-color:white; 
}

.iconAreaButton{
  position: relative; 
  top: 50%;
  transform: translateY(-50%);
}

/* focus時before擬似要素に色がつくので透明にする */
.iconAreaButton:focus::before{
  background-color: rgba(255,255,255,0)
}

.remainingPriceArea{
  flex: 1;
  text-align: center;
  animation: fadeIn 1s;
}

.hideButtonArea{
  width: 10%;
  text-align: center;
}

.truckIcon{
  height: 100%;
}

.closeIcon{
  height: 100%;
}

.remainingPrice{
  font-weight: bold;
  font-size: 130%;
}

.freeShippingText{
  color: red;
}

.popupMinimized {
  transition-duration: 0.6s;
  transition-timing-function: ease;
  width: 80px !important;
  border-radius: 40px;
  padding-left: 0px;
}

.displayNone{
  display: none;
}

@keyframes fadeIn{
  0%{
    opacity: 0;
  }
  70%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
</style>
